import ArrowLeftIcon from '../svg/arrow-left-icon.svg';
import ArrowRightIcon from '../svg/arrow-right-icon.svg';
import BinIcon from '../svg/bin-icon.svg';
import ChevronLeftIcon from '../svg/chevron-left-icon.svg';
import ChevronLeftSmIcon from '../svg/chevron-left-sm-icon.svg';
import ChevronRightIcon from '../svg/chevron-right-icon.svg';
import ChevronRightSmIcon from '../svg/chevron-right-sm-icon.svg';
import ChevronRightThinIcon from '../svg/chevron-right-thin-icon.svg';
import CrossIcon from '../svg/cross-icon.svg';
import CrossSmIcon from '../svg/cross-sm-icon.svg';
import EmptyCartIcon from '../svg/empty-cart-icon.svg';
import HamburgerMenuIcon from '../svg/hamburger-menu-icon.svg';
import HeartFilledIcon from '../svg/heart-filled-icon.svg';
import HeartIcon from '../svg/heart-icon.svg';
import LogoutOutlinedIcon from '../svg/logout-outlined-icon.svg';
import MinusIcon from '../svg/minus-icon.svg';
import PlusIcon from '../svg/plus-icon.svg';

import { ButtonSizeVariant } from './createButtonClasses';

export const buttonIconComponents = {
  PlusIcon,
  MinusIcon,
  ChevronRightIcon,
  ChevronRightSmIcon,
  ChevronRightThinIcon,
  ChevronLeftIcon,
  ChevronLeftSmIcon,
  BinIcon,
  EmptyCartIcon,
  LogoutOutlinedIcon,
  CrossIcon,
  CrossSmIcon,
  HamburgerMenuIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  HeartIcon,
  HeartFilledIcon,
};

export const buttonIconSizes: Record<ButtonSizeVariant, string> = {
  xs: 'w-2 w-2',
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  '2md': 'w-6 h-6',
  base: 'w-6 h-6',
  lg: 'w-6 h-6',
};
