import { TextAndImageSection as TextAndImageSectionType } from '@/core/types';
import { cn } from '@/core/ui/utils';
import { Img } from '@lib/img/components/Img';
import { Media } from '@lib/theme/components/Media';

import { TextAndImagePortableText } from './components/TextAndImagePortableText';

interface TextAndImageSectionProps {
  section: TextAndImageSectionType;
}

const IMAGE_WIDTH = 228;
const IMAGE_HEIGHT = 410;

export const TextAndImageSection = ({
  section: {
    textRaw,
    title,
    webImage: { desktopImageUrl },
  },
}: TextAndImageSectionProps) => (
  <div
    className={cn(
      'flex',
      'items-start',
      'rounded-xl',
      'bg-gray-050',
      'mx-5',
      'px-5',
      'md:px-6',
      'pb-5',
      'mt-8',
      'lg:mt-20',
      'lg:pb-12',
      'lg:pr-18',
      'lg:pl-0',
      'lg:mb-4',
      'xl:max-w-7xl',
      'xl:mx-auto'
    )}
  >
    <Media greaterThanOrEqual="lg">
      <div className={cn('flex-[1_0_20%]', 'mx-16', 'mt-14')}>
        <Img
          src={desktopImageUrl}
          alt={`${title} section image`}
          width={IMAGE_WIDTH}
          height={IMAGE_HEIGHT}
        />
      </div>
    </Media>
    <div
      className={cn(
        'flex',
        'flex-col',
        'font-sans',
        'text-gray-900',
        'text-sm',
        'mt-5',
        'lg:mt-14'
      )}
    >
      <h4 className={cn('font-medium', 'text-lg', 'md:text-2xl', 'leading-6')}>
        {title}
      </h4>
      <TextAndImagePortableText content={textRaw} />
    </div>
  </div>
);
