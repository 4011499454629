import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextProps,
} from '@portabletext/react';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

export const Block: PortableTextBlockComponent = ({ children }) => (
  <p className={cn('mt-[18px]')}>{children}</p>
);

export const TextAndImagePortableText: FCC<{ content: unknown }> = ({
  content,
}) => (
  <PortableText
    value={content as PortableTextProps['value']}
    components={{
      block: Block,
    }}
  />
);
