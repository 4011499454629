import { secondsToMilliseconds } from 'date-fns';
import { KeenSliderHooks } from 'keen-slider';
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';

import { useMedia } from '@lib/theme/hooks/useMedia';

type UseSliderProps = KeenSliderOptions & {
  durations?: (number | null | undefined)[];
};

type SliderEvents =
  | KeenSliderHooks
  | 'mouseEnterNavigationButton'
  | 'mouseLeaveNavigationButton';

export const useSlider = (props?: UseSliderProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const { windowInnerWidth } = useMedia();

  const [totalSlides, setTotalSlides] = useState<number>();

  const sliderOptions: KeenSliderOptions = useMemo(
    () => ({
      ...props,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
        props?.slideChanged?.(slider);
      },

      created(slider) {
        setLoaded(true);
        props?.created?.(slider);
      },
    }),
    [props]
  );

  const [sliderRef, instanceRef] = useKeenSlider<
    HTMLDivElement,
    NonNullable<unknown>,
    NonNullable<unknown>,
    SliderEvents
  >(sliderOptions, [
    (slider) => {
      const clearNextTimeout = () => {
        if (timeoutRef?.current) {
          clearTimeout(timeoutRef.current);
        }
      };

      const nextTimeout = () => {
        if (timeoutRef?.current) {
          clearTimeout(timeoutRef.current);
        }

        const slide = slider.track.details.rel;
        const duration = props?.durations?.at(slide);

        if (!duration) return null;

        timeoutRef.current = setTimeout(() => {
          instanceRef.current?.next();
        }, secondsToMilliseconds(duration));
      };

      slider.on('created', (instance) => {
        setTotalSlides(instance.slides.length);

        slider.container.addEventListener('mouseover', () => {
          clearNextTimeout();
        });
        slider.container.addEventListener('mouseout', () => {
          nextTimeout();
        });
        nextTimeout();
      });

      slider.on('dragStarted', clearNextTimeout);

      slider.on('updated', () => {
        nextTimeout();
      });

      slider.on('optionsChanged', (instance) => {
        setTotalSlides(instance.slides.length);
      });

      slider.on('animationEnded', () => {
        const { rel, progress } = slider.track.details;
        if (rel === 0 && progress !== 0) {
          slider.moveToIdx(0, true, { duration: 0 });
        }
        nextTimeout();
      });

      slider.on('mouseEnterNavigationButton', () => {
        clearNextTimeout();
      });
      slider.on('mouseLeaveNavigationButton', () => {
        nextTimeout();
      });
    },
  ]);
  const slidesPerView =
    (instanceRef?.current?.options?.slides as { perView: number } | undefined)
      ?.perView || 1;
  const isLoop = Boolean(instanceRef?.current?.options?.loop);

  useEffect(() => {
    if (!instanceRef.current || !loaded) return;
    const currentSliderInstance = instanceRef.current;

    const isDraggable = currentSliderInstance.options.drag;

    if (totalSlides === slidesPerView) {
      currentSliderInstance.update({ ...sliderOptions, drag: false });
    } else if (!isDraggable) {
      currentSliderInstance.update({ ...sliderOptions, drag: true });
    }
  }, [
    instanceRef,
    loaded,
    sliderOptions,
    slidesPerView,
    totalSlides,
    windowInnerWidth,
  ]);

  const handleMouseEnter = () => {
    instanceRef?.current?.emit('mouseEnterNavigationButton');
  };

  const handleMouseLeave = () => {
    instanceRef?.current?.emit('mouseLeaveNavigationButton');
  };

  const handleDotClick = (slide: number) => {
    instanceRef.current?.moveToIdx(slide);
  };

  const handlePrev = (e: MouseEvent<HTMLButtonElement>) => {
    if (instanceRef?.current?.prev()) {
      instanceRef.current.prev();
    } else {
      e.stopPropagation();
    }
  };

  const handleNext = (e: MouseEvent<HTMLButtonElement>) => {
    if (instanceRef.current?.next()) {
      instanceRef.current?.next();
    } else {
      e.stopPropagation();
    }
  };

  const showControls =
    loaded && totalSlides && totalSlides > 1 && slidesPerView !== totalSlides;

  const isPrevDisabled = currentSlide === 0;

  const isNextDisabled =
    instanceRef?.current?.track?.details?.rel ===
    instanceRef?.current?.track?.details?.maxIdx;

  const dotsLength = useMemo(() => {
    if (totalSlides && totalSlides <= slidesPerView) return 0;

    if (slidesPerView > 1 && !isLoop) {
      return Number(instanceRef?.current?.track?.details?.maxIdx) + 1;
    }

    return totalSlides;
  }, [instanceRef, isLoop, slidesPerView, totalSlides]);

  return {
    sliderRef,
    handlePrev,
    handleNext,
    currentSlide,
    handleDotClick,
    showControls,
    handleMouseLeave,
    handleMouseEnter,
    isPrevDisabled,
    isNextDisabled,
    loaded,
    dotsLength,
  };
};
